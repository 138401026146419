import React from 'react';
import '../styles/Videos.css';
// import YouTubeIcon from '@mui/icons-material/YouTube';
import VideoPlayer from './VideoPlayer';

export default function Videos() {
    // const [watch, setWatch] = useState(false);

    // return (
    //     <div className={`video-wrapper ${watch ? 'show-video' : ''}`}>
    //         <VideoPlayer />
    //         {watch && 
    //             <div className='videoWrapper'>
    //                 <iframe width="560" height="315" src="https://www.youtube.com/embed/1Vz14lmU4I0?si=7Qiq1xCqKQsV06fd" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    //             </div>
    //         }    
    //         <button className='video-button' onClick={() => setWatch(!watch)}>
    //             <YouTubeIcon/>사용법 영상 {watch ? "닫기" : "보기"}
    //         </button>
    //     </div>
    // );
    return (
        <div className='videos'>
            <div className='video'>
                <h2> 구매 절차 안내 </h2>
                <VideoPlayer type="buy"/>
            </div>
            <div className='video'>
                <h2>판매 절차 안내 </h2>
                <VideoPlayer type="sell"/>
            </div>
        </div>
        
    )
}


  

