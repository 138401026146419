import * as React from 'react';
import '../styles/Contact.css';
import {Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation } from "react-router-dom";

export default function FAQ() {
  const location= useLocation();
  const [expanded, setExpanded] = React.useState(null);
  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const questions = [
    {
      question: "모빅매니아는 어떤 서비스인가요?",
      answer: "모빅매니아는 사용자가 거래상대방 및 가격을 스스로 선택하여, 비트모빅을 P2P로 거래할 수 있도록, 관련 기능을 지원하는 애플리케이션 입니다. 그에 더해 간단한 시장 거래데이터, 업계 주요 칼럼 및 뉴스 등을 모바일에서도 즐길 수 있도록 제공해 드립니다.",
    },
    {
      question: "기존 거래방식과는 어떤 점이 다른가요?",
      answer: "거래 일방이 먼저 대상물을 넘겨줘야만 하는 구조를 벗어나, 멀티시그(다중서명)기술을 활용하여, 스스로의 통제권 하에서 거래를 완료시킬 수 있도록 구현하였습니다. 따라서 불특정 상대와의 거래 혹은 커다란 금액의 거래에도 안심하고 임하실 수 있습니다. 자세한 거래방식은 상단 메뉴의 사용법 섹션 또는 앱 고객센터 내 사용자가이드에서 확인하실 수 있습니다.",
    },
    {
      question: "판매할 모빅을 전송했는데, 계좌로 판매대금이 입금되지 않으면 어떻게 되나요?",
      answer: "원화 입금을 확인하신 후, 스스로 확인버튼을 누르기 전까지는 모빅이 구매자에게로 전송되지 않습니다.",
    }, 
    {
      question: "원화를 판매자의 계좌로 송금했는데도 불구하고, 모빅을 받지 못하게 되는 일은 없을까요?",
      answer: "판매자가 합의된 액수의 모빅을 입금한 이후, 구매자의 원화송금이 진행됩니다. 거래주소로 입금된 비트모빅은 판매자가 일방적으로 회수할 수 없습니다.",
    },
    {
      question: "판매자가 원화를 입금받은 후에도 확인버튼을 누르지 않으면 어떻게 되나요?",
      answer: "CS 확인 후 거래가 재개되며 양 측 주장 대치 시, 모빅은 거래주소에 남아있게 되며, 일방이 회수 또는 전송할 수 없습니다. 기망 혹은 범법행위에 대해서는 수사기관에 정확한 데이터가 제공되어, 자산의 회복은 물론 범법자에 대한 확실한 응징을 담보합니다."
    },
    {
      question: "법률을 준수하는 서비스인가요? 개인정보 관리는 어떻게 이루어지나요?",
      answer: "네 모빅매니아는 모든 국내법규를 준수합니다. 사용자는 가입시 휴대폰 본인인증 절차를, 첫 거래시 은행계좌 1원인증 절차를 거치게 됩니다. 따라서 잠재 범법행위자의 반칙행위를 방지하고, 유사 시 자산회복 및 처벌가능성을 담보합니다. 모든 개인정보는 안전하게 암호화 처리되어, 오직 서비스 제공 목적으로만 사용되며, 개인정보 취급에 대한 모든 관계 법령을 철저히 준수합니다."
    },
    {
      question: "해외에 있는 사용자도 이용이 가능한가요?",
      answer: "1차 오픈은 휴대폰 본인인증이 가능한 사용자 대상입니다만, 빠른 시일 내에 글로벌 KYC 솔루션을 도입하여 글로벌 버전으로 고도화 할 예정입니다."
    },
    {
      question: "향후에 서비스 혹은 기능확장 계획이 있나요?",
      answer: "네, 향후 비트코인과 비트모빅을 함께 보관할 수 있는 Wallet 기능이 추가될 예정이며, 자연스럽게 비트코인에 대한 원화거래와 비트코인-비트모빅 간의 P2P 거래도 가능하도록 구현할 예정입니다. 또한 라이트닝 네트워크와의 연동을 통한 라이트닝 Wallet 기능도 계획되어 있습니다."
    }
  ];


  return (
    <div className="contact-wrapper">
        {location.pathname === "/faq-mobile" ? <div style={{height: '20px', width: '100vw'}}></div> :
        <h1 className="gradient"> FAQ </h1> }
        <div className='acc-wrapper'>
            {questions.map((val, key) => {
              return (
              <Accordion sx={{
                '&:before': {
                    display: 'none',
                },
                borderRadius: '16px',
                className: "accordion"}}
                key = {key}
                expanded = {expanded === key}
                onChange={handleChange(key)}
                >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4>{val.question}</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{val.answer}</p>
                </AccordionDetails>
              </Accordion>);

            })}
          
          
        </div>
    </div>
  );
}