import React from 'react';
import {Tabs, Tab} from '@mui/material';
import {PersonalInfo, Terms} from '../components/Text.js';

export default function Policy() {
  const [value, setValue] = React.useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    <div>
        <div className="tabs">
            <Tabs
            value={value}
            onChange={handleChange}
            variant = "fullWidth"
            textColor="primary"
            
            indicatorColor="primary"
            aria-label="primary tabs example"
            >
                <Tab value="one" label={<Label name="이용약관"/>}/>
                <Tab value="two" label={<Label name="개인정보처리방침"/>}/>
            </Tabs>
        </div>
        <div>
            <TabContent value={value} index={"one"}>
                <Terms className="info" />
            </TabContent>
            <TabContent value={value} index={"two"}>
                 <PersonalInfo className="info"/>
            </TabContent>
        </div>
    </div>
    
  );
}

function TabContent(props) {
    const {value, index, children} = props;
    
    return (
        <div className="tabcontent" id={value}>
            {value === index && children}
        </div>
    )
}

function Label(props) {
    const name = props.name;
    return (
        <h4 className="customtab">{name}</h4>
    )
}