import React from 'react';

export default function VideoPlayer({type}) {
  return (
   type === "buy" ? <div className='videoWrapper'>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/xchwPLCA6a0?si=jOL1ZohYXCSQ3T1A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div> : <div className='videoWrapper'>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/1w3kaQRPRHs?si=vuVFMF7EwQism_Oz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
  );
};