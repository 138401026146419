import * as React from 'react';
import '../styles/Contact.css';
import Form from '../components/Form';


function Contact() {
    
    return (
        <div className="contact-wrapper">
            <h1 className="gradient"> Contact Us </h1>
            <Form />
        </div>
    );
  };
  
  export default Contact;