import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import Home from './pages/Home';
import Process from './pages/Process';
import FAQ from './pages/FAQ';
import Contact from './pages/Contact';
import Fee from './pages/Fee.js';
import Policy from './pages/Policy';
import NotFound from './pages/NotFound';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from "@mui/material/styles";

const root = ReactDOM.createRoot(document.getElementById('root'));
const MobickTheme = createTheme({
  palette: {
    primary: {
      main: "#86C2DE"
    },
    secondary: {
      main: "#F5F2E8"
    }
  }
});
root.render(
  <ThemeProvider theme={MobickTheme} >
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Home />} />
            <Route path="how-it-works" element={<Process/>} />
            <Route path="faq" element={<FAQ/>} />
            <Route path="contact" element={<Contact/>} />
          </Route>
          <Route path="policy" element={<Policy />} />
          <Route path="fee" element={<Fee/>} />
          <Route path="faq-mobile" element={<FAQ/>} />
          <Route path="*" element={<NotFound/>} />
        </Routes>
    </BrowserRouter>
 </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
