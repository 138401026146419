import * as React from 'react';
import { Link } from "react-router-dom";
import Wavy from './Wavy';
import {ReactComponent as Logo}  from '../images/LOGO FINAL.svg';
import {ReactComponent as BBLogo}  from '../images/bb_logo.svg';
import EmailRoundedIcon from '@mui/icons-material/Email';

export default function Footer() {

  return ( 
    <>
        <Wavy />
        <footer>
        <div className='footer-wrapper'>
            <div className='footer-inner-wrapper'>
            <Link className='footer-download' to="/"
            onClick={() => {
                window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
                });
            }}
                >
                <Logo className='logo-img'/>
                <h3>MOBICK MANIA</h3>
            </Link>
            <div className="footer-menu">
            <Link className='link-policy link' to="/policy"
            onClick={() => {
                window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
                });
            }}>이용약관</Link>
                <Link 
                    className='link' 
                    to="/how-it-works"
                    onClick={() => {
                        window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                        });
                    }}
                >사용법</Link>
                <Link 
                    className='link' 
                    to="/faq"
                    onClick={() => {
                        window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                        });
                    }}
                    >FAQ</Link>
                    <Link 
                    className='link' 
                    to="/contact"
                    onClick={() => {
                        window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                        });
                    }}
                    ><EmailRoundedIcon /></Link>
                    
            </div>
            
            
            </div>
            <div className='business-info'>
            <a href="https://betweenbits.io/" target="_blank" rel="noreferrer"><BBLogo className='bb-logo-img'/></a>
            <p> 주식회사 비트윈비츠 | 대표자 김동욱 <br/>
            사업자등록번호 880-81-02704<br/>
            서울특별시 서초구 논현로 13길<br/>
            ©2023 BetweenBits. All rights reserved.<br/></p>
            </div>
            
        </div>
        </footer>
        
    </>
    
  
  );
}