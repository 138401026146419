import * as React from 'react';
import {Drawer} from '@mui/material';
import { Link } from "react-router-dom";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import EmailRoundedIcon from '@mui/icons-material/Email';

export default function TemporaryDrawer() {
  const [visible, setVisible] = React.useState(false);

  const toggleDrawer = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setVisible(!visible);
  };

  const DrawerMenu = () => (
    <div
        className="drawer"
        onClick={toggleDrawer()}
        onKeyDown={toggleDrawer()}
        >
        <Link to="/how-it-works" className='link'>사용법</Link>
        <Link to="/faq" className='link'>FAQ</Link>
        <Link to="/contact" className='link'><EmailRoundedIcon /></Link>
    </div>
  );

  return (
        <React.Fragment>
            <button aria-label="Drawer Button" className='drawer-toggle-button' onClick={toggleDrawer()}>
            <MenuRoundedIcon sx={{ color: 'var(--accent)' }}/>
            </button>
          
          <Drawer
            anchor={'right'}
            open={visible}
            onClose={toggleDrawer()}
            style={{minWidth: '300px'}}
          >
            <DrawerMenu />
          </Drawer>
        </React.Fragment>
  );
}
