import * as React from 'react';
import { Link, useLocation } from "react-router-dom";
import TemporaryDrawer from './Drawer';
import EmailRoundedIcon from '@mui/icons-material/Email';
import {ReactComponent as Logo}  from '../images/LOGO FINAL.svg';

export default function Navigation() {
  const location= useLocation();
  let onProcess = false;
  let onContact = false;
  let onFAQ = false;
    if (location.pathname === "/how-it-works") {
      onProcess = true;
    } else if (location.pathname === "/contact") {
      onContact = true;
    } else if (location.pathname === "/faq") {
      onFAQ = true;
    } 
  return ( 
    <nav>
    <div className='nav-wrapper'>
      <Link className='link main' to="/"><Logo /><h3>MOBICK MANIA</h3></Link>
      <div className="nav-menu">
        <Link to="/how-it-works"
          className={onProcess ? "active-link" : "link"}>
          사용법
          </Link>
        <Link to="/faq"
        className={onFAQ ? "active-link" : "link"}>FAQ</Link>
        <Link to="/contact"
        className={onContact ? "active-link" : "link"}><EmailRoundedIcon /></Link>
      </div>
      <TemporaryDrawer />
    </div>
    
  </nav>
  
  );
}