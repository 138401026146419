import * as React from 'react';
import {ReactComponent as NotFoundSvg}  from '../images/illustrations/notfound.svg';

export default function NotFound() {
  
    return (
        <div className="full-wrapper">
            <NotFoundSvg className='full-wrapper-img'/>
        </div>
    );
}