import * as React from 'react';
import '../styles/Text.css';

export default function Fee() {
  
    return (
        <div className="wrapper-fee">
            <FeeTable />
            <p>향후 추가기능들이 더해지면서, 다양한 등급별 혜택이 제공될 예정입니다.</p>
        </div>
    );
}


export function FeeTable() {
    const data = [
        {
            name: "BRONZE",
            var2: "0 - 49",
            var3: "-",
            var4: "-"
        },
        {
            name: "SILVER",
            var2: "50 - 299",
            var3: "1,000,000원 이상",
            var4: "Metrics ‘더 보기’ 클릭 가능, Column에 댓글 작성 가능"
        },{
            name: "GOLD",
            var2: "300 이상",
            var3: "10,000,000원 이상",
            var4: "Metrics ‘더 보기’ 클릭 가능, Column에 댓글 작성 가능"
        }
    ]
    return (
            <div className='tableWrap'>
                <table id="table_fee">
                    <tr>
                        <th rowspan="2">등급</th>
                        <th colspan="2" style={{textAlign: "center", borderBottom: "1px solid white"}}>조건</th>
                        <th rowspan="2">혜택</th>
                    </tr>
                    <tr>
                        <th>포인트</th>
                        <th>누적 거래액</th>
                    </tr>

                    {data.map((val, key) => {
                        return (
                            <tr key={key}>
                                <td>{val.name}</td>
                                <td>{val.var2}</td>
                                <td>{val.var3}</td>
                                <td>
                                    {val.var4}
                                </td>
                            </tr>
                        )
                    })}
                </table>
            </div>);
}
