import { useState, useRef } from "react";
import { TextField, Button } from "@mui/material";
import emailjs from '@emailjs/browser';

export default function Form() {
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [sent, setSent] = useState(0);

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('mobickmania_contact', 'template_1', form.current, 'RsRG5iDhfyPEqPd34')
      .then((result) => {
          console.log(result.text);
          setEmail("");
          setTitle("");
          setMessage("");
          setSent(1);
      }, (error) => {
          console.log(error.text);
          setSent(2);
      });
  };

  return (
    <div className="form-wrapper">
        <form ref={form} onSubmit={sendEmail}>
          <TextField
            fullWidth
            label="이메일"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            type="email"
            sx= {{marginTop: 0}}
            name="user_email"
          />
          <TextField
            fullWidth
            label="제목"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            margin="normal"
            required
            name="user_title"
          />
          <TextField
            fullWidth
            label="내용"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            margin="normal"
            required
            multiline
            rows={4}
            sx ={{borderRadius: '16px'}}
            name="message"
          />
          
          <Button variant="contained" type="submit" sx={{ my: 2, backgroundColor: 'var(--accent)', borderRadius: '16px', color: 'white', fontWeight: 700 }}>
          제출
          </Button>
        </form>
        {sent === 1 && <div className="status-msg success">
          <p>제출 완료 되었습니다. 감사합니다!</p>
        </div>}
        {sent === 2 && <div className="status-msg fail">
          <p>에러가 있습니다. 잠시 후  다시 제출 해보세요.</p>
        </div>}
    </div>
  );
}
