import '../styles/Process.css';
import Img1  from '../images/image1.png';
import Img2  from '../images/image2.png';
import Img3  from '../images/image3.png';
import Img4  from '../images/image4.png';
import Img5  from '../images/image5.png';
import Videos from '../components/Videos';
import { useEffect  } from 'react';

function Process() {
    useEffect(() => {
        const thumbnailUrl = "../images/thumb_process.png";
        document.getElementById("thumbnailMeta").setAttribute("content", thumbnailUrl);
      }, []);

    function scrollToId() {
        const myId = "process";
        const elem = document.getElementById(myId);
        if (elem) {
          elem?.scrollIntoView({ behavior: "smooth" });
        }
    }
    return (
        <div className="process-wrapper">
            <h1 className="gradient"> 비트모빅 거래가 어떻게 진행되나요? </h1>
            <button className="text-button" onClick={scrollToId}>사용법으로 가기 👇</button>
            <div id="video">
                <Videos />
            </div>
            <div id="process" className='process'>
                <div className='step'>
                    <div className='step-img'>
                        <img alt="step 1" src={Img1} className='step-image'/>
                    </div>
                    <div className='step-info'>
                        <li className='step-num'>01</li>
                        <h3>사용자 가입</h3>
                        <p>앱 설치 후, 본인인증을 통해 사용자 가입 (계좌 등록)</p>
                    </div>

                </div>
                <div className='step'>
                    <div className='step-img'>
                        <img alt="step 2" src={Img2} className='step-image'/>
                    </div>
                    <div className='step-info'>
                        <li className='step-num'>02</li>
                        <h3>거래 제안 or 참여</h3>
                        <p>
                            판매/구매를 원하는 수량과 가격을 입력하여 거래를 제안
                            <br />
                            판매/구매 제안을 선택하여 거래에 참여
                            </p>
                    </div>

                </div>
                <div className='step'>
                    <div className='step-img'>
                        <img alt="step 3" src={Img3} className='step-image'/>
                    </div>
                    <div className='step-info'>
                        <li className='step-num'>03</li>
                        <h3>비트모빅 송금</h3>
                        <p><span className='step-tag'>판매자</span></p>
                        <p>거래주소로 판매하고자 하는 비트모빅을 전송</p>
                    </div>

                </div>
                <div className='step'>
                    <div className='step-img'>
                         <img alt="step 4" src={Img4} className='step-image'/>
                    </div>
                    <div className='step-info'>
                        <li className='step-num'>04</li>
                        <h3>원화 송금</h3>
                        <p><span className='step-tag'>구매자</span></p>
                        <p> 판매자의 모빅입금을 확인 후, 등록된 판매자의 계좌로 원화 송금</p>
                    </div>
                </div>
                <div className='step'>
                    <div className='step-img'>
                        <img alt="step 5" src={Img5} className='step-image'/>
                    </div>
                    <div className='step-info'>
                        <li className='step-num'>05</li>
                        <h3>거래 완료</h3>
                        <p>판매자의 원화입금 확인과 동시에, 거래주소에서 구매자 주소로 비트모빅 전송(거래완료)</p>
                    </div>

                </div>
            </div>
            
        </div>
    );
  };
  
  export default Process;