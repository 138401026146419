import React, {lazy, Suspense} from 'react';
import '../styles/Home.css';
import HeroImg  from '../images/hero-img.png';
import Google  from '../images/google.png';
import GoogleNew  from '../images/google.webp';
import AppStore  from '../images/appstore.png';
import AppStoreNew  from '../images/appstore.webp';
// import Popup from '../components/Popup';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Link } from "react-router-dom";


const Features = lazy(() => import('../components/Features.js'));
const Whys = lazy(() => import('../components/Whys.js'));
const Counter = lazy(() => import('../components/Counter.js'));
const Slider = lazy(() => import('../components/Slider.js'));

function Home() {
    const renderLoader = () => <div className='container-loading'></div>;
    return (
        <div>
            <Hero />
            <Suspense fallback={renderLoader()}>
                <Slider className="only-mobile"/>
                <Counter />
                <Features />
                <Whys />
            </Suspense>
        </div>
    );
  };
  
  export default Home;


  function Hero() {
    return(
        <div className="hero">
            {/* <Popup /> */}
                <div className="hero-info">
                    <h1 className='gradient' style={{wordBreak: "keep-all"}}>비트모빅을 거래하는 가장 안전하고 편리한 방법</h1>
                    <h2 className='main-h2'>The most secure and convenient way to exchange <span className="underlined">BTCMobick</span> with peers</h2>
                    <Link to="/how-it-works">
                        <button className='video-player-button'>
                            <YouTubeIcon/>사용법 영상 열기
                        </button>
                    </Link>
                    <div className='download-logos'>
                        <div className='download-logo'>
                            <a href="https://play.google.com/store/apps/details?id=com.btw.mobickmania" target="_blank" rel="noreferrer">
                                <picture>
                                <source type="image/webp" srcSet={GoogleNew}/>
                                <source type="image/png" srcSet={Google}/>
                                <img alt="play store icon"src={Google} height="50px"  width="168px"/>
                                </picture>
                            </a>
                        </div>
                        <div className='download-logo'>
                            <a href="https://apps.apple.com/kr/app/mobick-mania/id6466781851" target="_blank" rel="noreferrer">
                                <picture>
                                <source type="image/webp" srcSet={AppStoreNew}/>
                                <source type="image/png" srcSet={AppStore}/>
                                <img alt="app store icon" src={AppStore} height="50px" width="149.33px"/>
                                </picture>
                                </a>
                        </div>
                        
                        
                    </div>
                    
                        
                    
                    
                </div>
                <div className="hero-img-wrapper">
                    <img alt="three phones" src={HeroImg} className='hero-img'/>
                </div>
        </div>
        
    )
  }