import '../styles/Text.css';
import * as React from 'react';
import { useState } from 'react';



export function Terms() {
    return (
        <React.Fragment>
            <h2 className='bigHeading' style={{textAlign: "center"}}>서비스 이용약관</h2>
            <ul>
                <li>
                    <h3 className="miniHeading">제 1 장 총칙</h3>
                    <h4>제 1 조 (목적)</h4>
                    <p>
                    이 약관은 주식회사 비트윈비츠(이하 “회사”라고 합니다.)가 모바일 애플리케이션을 통하여 제공하는 서비스와 관련하여 회사와 사용자간의 권리와 의무, 책임사항 및 사용자의 서비스이용절차에 관한 사항을 규정함을 목적으로 합니다. 
                    </p>
                    <h4>제 2 조 (약관의 명시, 효력 및 변경)</h4>
                    <ol>
                        <li>회사는 이 약관을 서비스를 이용하고자 하는 자와 사용자가 알 수 있도록 인터넷사이트 (<a target="_blank" rel="noreferrer" href="http://www.mobickmania.com">http://www.mobickmania.com</a>) 화면에 게시합니다.</li>
                        <li>회사가 이 약관을 개정하는 경우에는 개정된 약관의 적용일자 및 개정사유를 명시하여 그 적용일자 7일 이전부터 적용일자 전일까지 위 1항의 방법으로 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 회사는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.</li>
                        <li>이 약관은 회사와 사용자간에 성립되는 서비스이용계약의 기본약정입니다. 회사는 필요한 경우 특정 서비스에 관하여 적용될 사항(이하 “운영정책”이라고 합니다.)을 정하여 미리 공지할 수 있습니다. 사용자가 이러한 운영정책에 동의하고 특정 서비스를 이용하는 경우에는 운영정책이 우선적으로 적용되고, 이 약관은 보충적인 효력을 갖습니다. 운영정책의 변경에 관해서는 위 2항을 준용합니다.</li>
                    </ol>
                    <h4>제 3 조 (관련법령과의 관계)</h4>
                    <ol>
                        <li>이 약관 또는 운영정책에서 정하지 않은 사항은 관련법령의 규정과 일반적인 상관례에 의합니다.</li>
                        <li>사용자는 회사가 제공하는 서비스를 이용함에 있어서 ‘전자상거래 등에서의 소비자보호에 관한 법률’, ‘전자거래기본법’, ‘소비자보호법’, ‘표시·광고의 공정화에 관한 법률’, ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’ 등 관련법령을 준수하여야 하며, 이 약관의 규정을 들어 관련법령 위반에 대한 면책을 주장할 수 없습니다.</li>
                    </ol>
                    <h4>제 4 조 (서비스의 종류)</h4>
                    <p>
                    회사가 사용자에게 제공하는 서비스는 다음과 같습니다.
                    <ol>
                        <li>
                        P2P 거래를 위한 게시판 및 관련 소프트웨어 <br />
                        회사가 모바일 애플리케이션(mobickmania)의 형태로 사용자들이 자신의 통제하에 있는 코인을 특정한 조건하에 상대 사용자 및 거래조건을 선택하여 직접 상호 거래할 수 있도록 필요한 게시판과 관련 소프트웨어 서비스를 제공합니다. 
                        </li>
                        <li>
                        기타 정보서비스 <br />
                        게시판 및 관련소프트웨어 외에 사용자들에게 유용한 시장 데이터와 함께 업계 주요 뉴스 및 칼럼을 애플리케이션을 통해 제공합니다.
                        </li>
                    </ol>
                    </p>
                    <h4>제 5 조 (용어의 정의)</h4>
                    <p> 
                        아래의 페이지에서 본 약관에서 사용하는 용어의 정의를 확인 할 수 있습니다. <br />
                        <a href="#words">용어 정의 자세히 보기</a>
                    </p>
                    <h4>제 6 조 (서비스의 성질과 목적)</h4>
                    <p>
                    회사는 사용자에게 코인을 판매하거나 사용자로부터 코인을 구매하지 않으며, 단지 사용자 각자의 자기결정에 의한 거래의 안전성 및 신뢰성을 증진시키는 도구만을 제공합니다. 사용자간에 성립된 거래와 관련된 책임은 거래당사자인 사용자들 스스로가 부담하여야 합니다.
                    <br />
                    회사는 회사가 제공하는 편의기능을 통하지 않은 사용자간의 직거래로 인하여 발생한 문제에 대하여 책임지지 않습니다.
                    </p>
                    
                </li>
                <li>
                    <h3 className="miniHeading">제 2 장 서비스 이용계약</h3>

                    <h4>제 7 조 (서비스 이용계약)</h4>
                    <ol>
                        <li>
                            회사가 제공하는 서비스에 관한 이용계약(이하 “이용계약”이라고 합니다.)은 서비스를 이용하고자 하는 자가 회사가 정한 가입 양식에 따라 사용자정보를 기입한 후 이 약관에 동의한다는 의사표시를 하면, 회사는 이를 이용신청으로 보고, 승낙함으로써 성립합니다.
                        </li>
                        <li>
                            이메일(ID)는 실명 1인당 1개의 아이디만을 사용할 수 있으며, 이용계약은 이메일(ID) 단위로 체결하는 것을 원칙으로 합니다.
                        </li>
                        <li>
                            서비스를 이용하고자 하는 자는 제1항의 이용신청 시 반드시 본인의 실제 정보를 기재하여야 합니다. 그러지 아니한 경우 사용자는 법적인 보호를 받을 수 없으며, 이에 따른 민사, 형사상의 모든 책임은 가입한 사용자에게 있습니다. 또한 이용약관 제17조에 따라 서비스 이용에 제한이 있을 수 있습니다.
                        </li>
                        <li>
                            회사는 사용자에게 회사의 서비스  및 관련서비스에 대한 다양하고 유익한 정보를 E-mail 등을 통하여 제공할 수 있습니다.
                        </li>
                    </ol>
                    <h4>제 8 조 (이용신청 및 거부)</h4>
                    <ol>
                        <li>
                        회사는 이용신청자가 회사가 정한 가입양식을  정확하게 기재하고 이 약관에 동의한 경우에는 이 약관에 규정된 실명확인절차를 거쳐 서비스의 이용을 승낙하는 것을 원칙으로 합니다.
                        </li>
                        <li>
                        회사는 법령에 따라 신용정보업자 또는 신용정보집중기관의 휴대폰 인증 방법을 통하여 개인정보와 성명의 일치 여부를 확인함으로써, 실명확인절차를 취합니다. 회사는 실명확인절차를 취할 수 없는 이용신청에 대해서는 신청자에게 증빙자료를 요청할 수 있습니다.
                        </li>
                        <li>
                        만 19세 미만 사용자는  서비스를 이용할 수 없으며, 기타 일부 서비스가 제한될 수 있습니다.
                        </li>
                        <li>
                            회사는 다음과 같은 사유가 있는 경우, 이용신청에 대한 승낙을 거부할 수 있습니다.
                            <ol>
                                <li>회사의 실명확인절차에서 실명가입신청이 아님이 확인된 경우</li>
                                <li>이미 가입된 사용자와 이름 및 개인정보가 동일한 경우</li>
                                <li>이 약관 제9조에 기하여 회사가 이용계약을 해지한 전(前)사용자가 재이용신청을 하는 경우</li>
                                <li>이 약관 제17조에 기하여 회사로부터 사용자자격정지조치를 받은 사용자가 이용정지 중에 이용계약을 임의 해지하고 재이용신청을 하는 경우</li>
                                <li>만 14세 미만의 자가 이용신청을 하는 경우</li>
                                <li>가입 신청 시 기재하여야 할 필수기재항목에 허위사실을 기재한 경우</li>
                                <li>기타 위법 또는 부당한 이용신청임이 확인된 경우</li>
                            </ol>
                        </li>
                        <li>
                        회사는 다음과 같은 사유가 있는 경우, 이용신청에 대한 승낙을 유보할 수 있습니다. 이 경우, 회사는 이용신청자에게 승낙유보의 사유, 승낙가능시기 또는 승낙에 필요한 추가요청정보 내지 자료 등 기타 승낙유보와 관련된 사항을 해당 서비스화면에 게시하거나 E-mail을 통하여 통지합니다.
                        <ol>
                            <li>설비에 여유가 없는 경우</li>
                            <li>기술상 지장이 있는 경우</li>
                            <li>회사의 실명확인절차에서 실명가입신청 여부가 확인되지 않는 경우</li>
                            <li>기타 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우</li>
                        </ol>
                        </li>
                    </ol>
                    <h4>제 9 조 (이용계약의 종료)</h4>
                    <ol>
                        <li>사용자는 이 약관에서 정한 절차에 따라 이용계약을 해지할 수 있습니다.</li>
                        <li>
                        사용자의 해지
                        <ol>
                            <li>사용자는 언제든지 회사에게 해지의사를 통지함으로써 이용계약을 해지할 수 있습니다. 다만, 사용자는 해지의사를 통지하기 전에 모든 구매 또는 판매중인 매매절차를 완료, 철회 또는 취소해야만 해지의 의사표시를 할 수 있습니다. 이 경우 매매의 철회 또는 취소로 인한 불이익은 사 자 본인이 부담하여야 합니다.</li>
                            <li>이용계약은 사용자의 해지의사가 회사에 도달한 때에 종료됩니다.</li>
                            <li>본 항에 따라 해지를 한 사용자는 이 약관이 정하는 사용자가입절차와 관련조항에 따라 사용자로 다시 가입할 수 있습니다.</li>
                        </ol>
                        단, 이 약관 제10조 7항에 따라 회사가 사용자의 정보를 보존하고 있는 기간 내 재가입할 경우 사용자는 기존에 사용하였던 이메일(ID)로만 재이용이 가능합니다.
                        </li>
                        <li>회사의 해지</li>
                        <ol>
                            <li>
                            회사는 다음과 같은 사유가 있는 경우, 이용계약을 해지할 수 있습니다. 이 경우 회사는 사용자에게 E-mail, 전화, 기타의 방법을 통하여 해지사유를 밝혀 해지의사를 통지합니다. 다만, 회사는 해당 사용자에게 사전에 해지사유에 대한 의견진술의 기회를 부여할 수 있습니다.
                            <br />가) 사용자에게 제8조 4항에서 정하고 있는 이용계약의 승낙거부사유가 있음이 확인된 경우
                            <br />다) 사용자가 회사나 다른 사용자, 기타 타인의 권리나 명예, 신용, 기타 정당한 이익을 침해하는 행위를 한 경우
                            <br />라) 사용자가 실제의 서비스 이용 의사 없이 당사가 제공하는 서비스 기능을 실행한 경우 
                            <br />마) 타인의 ID를 도용한 경우
                            <br />바) 본 약관의 내용을 위반하는 행위
                            </li>
                            <li>이용계약은 회사의 해지의사가 사용자에게 도달한 때에 종료되나, 사용자가 제공한 연락처 정보의 오류로 인하여 회사가 해지의사를 통지할 수 없는 경우에는 회사가 마이페이지에 해지의사를 공지하는 때에 종료됩니다.</li>
                            <li>회사가 이용계약을 해지하는 경우, 회사는 별도의 통지 없이 해당 사용자와 관련된 거래를 취소할 수 있습니다.</li>
                            <li>이용계약의 종료와 관련하여 발생한 손해는 이용계약이 종료된 해당 사용자가 책임을 부담하여야 하고, 회사는 일체의 책임을 지지 않습니다.</li>
                        </ol>
                    </ol>
                </li>
                <li>
                    <h3 className="miniHeading">제 3 장 사용자정보의 보호</h3>
                    <h4>제 10 조 (회사의 의무)</h4>
                    <ol>
                        <li>회사는 이용계약을 위하여 사용자가 제공한 정보 외에도 수집목적 또는 이용목적을 밝혀 사용자로부터 필요한 정보를 수집할 수 있습니다.   경우, 회사는 사용자로부터 정보수집에 대한 동의를 받습니다.</li>
                        <li>회사가 정보수집을 위하여 사용자의 동의를 받는 경우, 회사는 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공관련사항(제공 받는 자, 제공목적, 제공정보의 내용)을 미리 명시하거나 고지합니다. 사용자는 정보제공에 동의하더라도 언제든지 그 동의를 철회할 수 있습니다.</li>
                        <li>회사는 사용자의 원활한 상담 및 본인확인등을 위하여 사용자로부터 개인정보를 제공 받을 수 있으며, 사용자가 이를 거부할 경우 회사의 상담에 일부 제약이 있을 수 있습니다.</li>
                        <li>회사는 사용자 자신의 정보 열람 및 잘못된 정보에 대한 정정에 성실히 응하여야 합니다. 또한  회사는 사용자로부터 정보정정의 요청이 있는  경우 그 오류가 시정될 때까지 당해 정보를 이용하지 않습니다.</li>
                        <li>회사는 이메일(ID) 등 서비스화면을 통하여 공개되는 정보를 제외하고는 회사가 수집한 사용자정보를 정보수집 시에 밝힌 수집목적, 이용목적, 원활한 서비스의 제공 및 제공서비스의 확대 외의 용도로 사용하거나 당해 사용자의 동의 없이 제3자에게 제공하지 않습니다.
                            <br />
                            다만, 다음의 경우는 예외로 하며, 이 경우 회사는 동 정보를 열람할 수 있는 방법을 사용자에게 제공합니다.
                            <ol>
                                <li>법령이 사용자정보의 이용과 제3자에 대한 정보제공을 허용하고 있는 경우</li>
                                <li>통신비밀보호법, 부가가치세법 등 국가기관의 법령에 의한 자료제공요청이 있는 경우</li>
                            </ol>
                        </li>
                        <li>회사는 사용자정보의 보호를 위하여 관리자를 최소한으로 한정하며, 회사의 고의 또는 과실로 인하여 사용자정보가 분실, 도난, 유출, 위조, 변조 또는 훼손된  경우에는 그로 인한 사용자의 손해에 대하여 모든 책임을 부담합니다.</li>
                        <li>이용계약이 종료된 경우, 회사는 당해 사용자의 정보를 파기하는 것을 원칙으로 합니다. 다만, 관계  법령 및 운영방침에 의한 경우에는 그에  따라 보관 및 파기합니다. 자세한 내용은 개인정보처리방침에 따릅니다.</li>
                        <li>회사는 사용자정보의 보호와 관리에 관한 개인정보처리방침을 사용자와 회사의 서비스를 이용하고자 하는 자가 알 수 있도록 인터넷사이트 (<a target="_blank" rel="noreferrer" href="http://www.mobickmania.com">http://www.mobickmania.com</a>)에 공개합니다.</li>
                    </ol>
                    <h4>제 11 조 (사용자의 의무)</h4>
                    <ol>
                        <li>사용자는 회사에게 정보를 제공하고자 하는 경우 사실대로 제공하여야 합니다.</li>
                        <li>사용자는 이용신청 시 기재한 사항이 변경되었을 경우, 즉시 해당 사항을 수정해야 합니다. 단, 이메일(ID)은 수정할 수 없음을  원칙으로 합니다. 만약 사용자 정보가 변경되었음에도 이를  반영하지 않거나, 오기입으로써 발생하는 모든 피해는 사용자가 부담해야 합니다.</li>
                        <li>이메일(ID) 및 비밀번호(본인인증서비스를 통한 각종 인증번호 포함, 이하 "비밀번호"라고 합니다.)에 대한 관리책임은 사용자에게 있으며, 사용자는 이메일(ID) 및 비밀번호를 타인에게 양도, 대여할 수 없습니다.</li>
                        <li>사용자가 이메일(ID) 또는 비밀번호를 도난 당하거나 제3자가 이를 사용하고 있음을 인지한 경우, 즉시 회사에게 통보하여야 하고, 회사의 안내가 있는 경우에는 그에 따라야 합니다.</li>
                        <li>사용자는 회사의 이용약관 및 운영방침, 공지사항을 수시로 확인해야 하며, 확인하지 못하여 발생한 피해는 회사가 부담하지 않습니다.</li>
                        <li>거래 도중 발생하는 사용자의 단말 분실 혹은 파손에 기인한 자산손실은 사용자의 책임으로써, 관련하여 회사가 복구 의무를 부담하지 않습니다.</li>
                    </ol>
                </li>
                <li>
                    <h3 className="miniHeading">제 4 장 서비스의 이용</h3>
                    <h4>제 12 조 (서비스의 유지 및 중지)</h4>
                    <ol>
                        <li>회사는 서비스 제공 설비를 항상 운용 가능한 상태로 유지하기 위하여 노력하며 서비스 제공설비에 장애가 발생하거나 또는 그 설비가  멸실될 때 에는 이를 신속하게 수리, 복구해야 합니다.</li>
                        <li>회사는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다.
                            <ol>
                                <li>서비스용 설비의 보수 등 공사로 인한 부득이한 경우</li>
                                <li>전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지했을 경우</li>
                                <li>국가 비상 사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우</li>
                            </ol>
                        </li>
                        <li>위 제2항 각호의 경우에 의한 서비스 제공 중단 시 회사는 사용자들이 입은 손해에 대해 책임을 지지 않습니다.</li>
                    </ol>
                    <h4>제 13 조 (서비스 이용정지)</h4>
                    <p>
                        회사는 서비스를 통한 거래의 안전성과 신뢰성을 위하여 일정한 정지사유 발생 시 사용자자격을 정지할 수 있습니다. 서비스 이용 제한에 대해서는 회사가 정하는 <a href="#operationPolicy">서비스 운영정책</a>에 따릅니다.
                    </p>
                    <h4>제 14 조 (사용자의 등급 관리)</h4>
                    <ol>
                        <li>회사는 서비스의 신뢰성 향상을 위하여 등급 서비스를 제공합니다. 단, 사용자의 등급은 거래의 참고자료에 불과하며 사용자의 신용을 보증하거나  금융상의 신용상태를 의미하는 것은 아닙니다.</li>
                        <li> 사용자 등급은 회사가 정하는 <a href="#operationPolicy">서비스 운영정책</a>에 따릅니다.
                            <ol>
                                <li>회사의 분류기준에 따라 3단계의 사용자등급으로 구분 합니다.</li>
                                <li>사용자등급은 산정 기준에 따라 실시간 반영됩니다.</li>
                            </ol>
                        </li>
                        <li>회사는 서비스 신뢰성 향상을 위해 사용자등급 추출 방법, 등급 부여 일정, 등급별 혜택 사항을 변경 할 수 있습니다.</li>
                        </ol>
                        {/* 
                        <li>회사는 다음과 같은 사용자등급에 따라 사용자에게 거래 혜택을 제공하고 있습니다. </li>
                        <Table5 /> 
                        <p className="indented">4. 회사는 서비스 신뢰성 향상을 위해 사용자등급 추출 방법, 등급 부여 일정, 등급별 혜택 사항을 변경 할 수 있습니다.
                        </p>
                        */}
                    <h4>제 15 조 (대리 및 보증의 부인)</h4>
                    <ol>
                        <li>회사는 사용자 상호간의 거래를 위한 온라인 거래장소(marketplace)를 제공할 뿐이므로 코인을 판매하거나 구매하고자 하는 사용자를 대리하지 않습니다. 또한, 회사의 어떠한 행위도 판매자 또는 구매자를 대리하는 행위로 간주되지 않습니다.</li>
                        <li>회사는 서비스를 통하여 이루어지는 사용자간의 판매 및 구매와 관련하여 판매의사 또는 구매의사의 존부 및 진정성, 안전성, 적법성 및 타인의 권리에 대한 비침해성, 사용자가 입력하는 정보의 진실성 등 일체에 대하여 보증하지 아니하며, 이와 관련한 일체의 위험과 책임은 해당 사용자가 부담해야 합니다.</li>
                        <li>회사는 사용자가 게재하는 거래 포스팅과 관련하여 제반 정보를 통제하거나 제한하지 않습니다. 다만, 회사는 올려진 포스팅의 날짜가 지나치게 경과하여, 사용자의 유효한 이용행위와 관련성이 적어진다고 판단되는 경우에 해당 포스팅을 날짜에 맞추어 삭제할 수 있습니다. 사용자가 게재한 정보의 내용이 타인의 명예, 권리를 침해하거나 거래와 상관없는 내용 게재 및 법 규정을 위반한다고 판단하는 경우에는 이를 삭제할 수 있고, 기타 필요한 조치를 취할 수 있습니다.</li>
                    </ol>
                    <h4>제 16 조 (서비스 이용자의 의무)</h4>
                    <ol>
                        <li>사용자간 코인을 상호간 직접 거래하는 과정에서 회사가 제공하는 서비스를 이용하는 경우 회사가 정하는 양식에 어긋난 등록이나 허위 등록으로 인한 손해 또는 손실에 대한 책임은 사용자가 부담해야 합니다.</li>
                        <li>서비스 이용 중 거래당사자 간 분쟁이 발생할 경우, 회사는 거래 당사자 간 사실관계가 확인될 때까지 혹은 관련 당국의 판단이 있을 때까지 멀티시그 주소에 예치된 코인의 이동에 관한 서비스 제공을 보류할 수 있습니다.</li>
                        <li>서비스 이용 중 발생되는 사고 중 안전 거래 방법을 숙지하지 못하여 발생한 사고에 대한 책임은 고객에게 있으며, 자세한 안전거래 방법에 대해서는 회사가 정하는 <a href="#operationPolicy">서비스 운영정책</a>에 따릅니다.</li>
                        <li>거래 편의에 관련된 기능 및 시스템을 이용하는 대가로 발생하는 수수료는 판매사용자의 부담으로 합니다.</li>
                        <li>서비스를 이용하여 거래하는 경우 사용자는 당사자와 거래로 인한 위험이 없다고 스스로 판단하였을 경우 거래가 이루어지는 것입니다. 그러므로 서비스를  이용하여 사용자가 스스로 결정한 거래에 의해 발생하는 사기 등의 피해에 대하여 회사는 책임이 없습니다.</li>
                    </ol>
                    <h4>제 17 조 (사용자의 의무)</h4>
                    <p>사용자는 회사가 정하는 <a href="#operationPolicy">서비스 운영정책</a>을 준수하지 않아 발생하는 모든 책임은 사용자가 부담합니다.</p>
                </li>
                <li>
                    <h3 className="miniHeading">제 5 장 서비스 이용수수료</h3>
                    <h4>제 18 조 (수수료의 내용)</h4>
                    <ol>
                        <li>회사가 부과하는 수수료는 회사가 제공하는 시스템이용료입니다.</li>
                        <li>사용자간 코인 거래를 위해 의사표시를 게시하는 기능은 무료입니다.</li>
                        <li>회사가 제공하는 편의기능을 이용하여 사용자간 거래가 성공적으로 이루어진 경우 서비스 이용료를 부과합니다.
                            <br />
                            - 거래금액의 0.5%를 판매자에게 부과하며, 요율은 앱 내 공지사항을 통해 사전공지 후 변경될 수 있습니다.
                        </li>
                    </ol>
                    <h4>제 19 조 (수수료 징수 방법)</h4>
                    <p>사용자들의 거래가 정상 종료됨과 동시에 제27조에서 규정한 서비스 이용 수수료를 멀티시그 주소로부터 회사가 지정하는 주소로 해당 코인을 송금하는 방식으로 징수 합니다.</p>
                </li>
                <li>
                    <h3 className="miniHeading">제 6 장 면책조항 등</h3>
                    <h4>제 20 조 (회사의 면책)</h4>
                    <ol>
                        <li>회사는 사용자들에게 온라인 상의 거래장소를 제공하고 기타 부가정보를 제공함에 그치는 것이므로 서비스를 통하여 이루어지는 사용자 상호간의 거래와 관련된 문제로 인한 분쟁해결 등 필요한 사후처리는 거래당사자인 사용자들이 직접 수행하여야 합니다. 회사는 이에 대하여 관여하지 않으며 어떠한 책임도 부담하지 않습니다.</li>
                        <li>회사는 천재지변 또는 이에 준하는 불가항력, 정보통신설비의 보수점검, 교체 또는 고장, 통신의 두절 등으로 인하여 일시적 또는 종국적으로 서비스를 제공할 수 없는 경우, 서비스 제공에 관한 책임이 면제됩니다. 이 경우 회사는 회사가 제공하는 인터넷사이트 or 모바일 앱 화면에 게시하거나 기타의 방법으로 사용자들에게 통지합니다.</li>
                        <li>회사는 인터넷 이용자 또는 사용자의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.</li>
                        <li>회사는 서비스를 통하여 거래되는 코인거래정보 및 일시적 서버 등의 오류, 미비 등으로 인해 사용자가 가능한 손해를 입지 않도록 최선의 노력을 다하나, 그 기간동안의 시세변동 등 서비스를 사용치 못함으로 인한 사용자의 금전적 피해에 대해 보상의무를 지지는 않습니다. </li>
                        <li>회사는 사용자가 다른 사용자가 게재한 정보, 사실의 정확성 등을 신뢰함으로써 입은 손해에 대하여 책임을 지지 않습니다.</li>
                        <li>회사와 피연결회사(회사의 서비스 화면과 링크, 제휴 등으로 연결된 사이트를 운영하는 회사를 말합니다)는 독자적으로 운영되며, 회사는  피연결회사와 사용자간에 이루어진 거래 또는 계약에 대하여는 책임을 지지 않습니다.</li>
                    </ol>
                    <h4>제 21 조 (준거법 및 관할법원)</h4>
                    <p>
                    이 약관과 회사와 사용자간의 서비스이용계약, 사용자 상호간의 거래에 대해서는 대한민국 법령이 적용되며, 그에 관한 해석과 관할법원에 대하여는 대한민국 법령에 따릅니다.
                    </p>
                    <h4>제 22 조 (게시판의 이용)</h4>
                    <p>
                    회사가 모바일 애플리케이션을 통하여 제공하는 서비스 중 게시물 이용에 관련해서는 회사가 정하는 <a href="#operationPolicy">서비스 운영정책</a>에 따릅니다.
                    </p>
                    <h4>제 23 조 (기타조항)</h4>
                    <ol>
                        <li>회사는 사용자로부터 제기되는 불만사항 및 의견이 정당하다고 판단되는 경우 이를 신속하게 처리하며, 즉시처리가 곤란한 경우에는 사유와 처리기간을 통보합니다.</li>
                        <li>본 서비스를 위반하거나 서비스 이용 시 불편 사항이 발생한 경우 주식회사 비트윈비츠의 “문의하기”를 이용하시거나, 이메일로 문의 하여 주시기 바랍니다.</li>
                        <li>문의시간: 365일 24시간</li>
                        <li>문의 응대 시간: 월~금, 10:00~18:00</li>
                    </ol>
                    <p>회사소개</p>
                    <ul>
                        <li>회사명: 주식회사 비트윈비츠</li>
                        <li>주소: 서울 서초구 논현로 13길 19(킴스빌딩 2F)</li>
                        <li>사업자 등록번호: 880-81-02704</li>
                        <li>대표자: 김동욱</li> 
                    </ul>
                </li>
                <li>
                    <h3 className="miniHeading">부칙</h3>
                    <p>
                        이 약관은 <strong>2023년 11월 13일</strong>부터 적용되며, 이전의 약관은 본 약관으로 변경됩니다.
                        <br />
                        - 이용약관 시행일자 : 2023년 11월 13일
                    </p>
                </li>
            </ul>
            <section id="words">
                <h2 className='bigHeading'>용어 정의</h2>
                <Table6 />
            </section>
            <section id="operationPolicy">
                <h2 className='bigHeading'>서비스 운영정책</h2>
                <p>아래의 내용은 서비스 운영 상의 사정 변경 혹은 회사의 정책 변경에 따라, 세부 내용이 사전 고지 없이 변경될 수 있습니다.</p>
                <h3 className="miniHeading">보상 & 벌점 규정</h3>
                <p>1. 회사는 아래 표와 같이 판매자와 구매자에게 등급 업그레이드를 위한 포인트를 지급할 수 있습니다.</p>
                <Table7/>
                <p>2. 회사는 다음 각호의 사유로 인하여 판매자와 구매자에게 벌점을 부과할 수 있습니다.
                        <ol>
                            <li>판매자가 멀티시그주소로 코인을 입금하지 않아 세션이 종료된 경우</li>
                            <li>판매자가 멀티시그주소로 코인을 입금하지 않아 세션이 종료된 경우
                                <br />
                                위 경우에 기 입금된 코인은 네트워크 수수료를 제외하고 판매자에게 환불 처리
                            </li>
                            <li>구매자가 은행계좌로 송금하지 않고 세션이 종료된 경우</li>
                            <li>패널티 규정의 상세 내역은 아래와 같습니다.<br />
                                <span>
                                코인 송금 전 취소: -5 P<br/>
                                코인 송금 전 시간 초과: -5 P<br />
                                코인 송금 후 시간 초과: -10 P<br />
                                </span>
                            </li>
                        </ol>

                    </p>
                    <p>3. 사용자 상호 거래 진행 간 구매자가 은행계좌이체를 진행하지 않아서 판매자가 추가 진행을 하지 못하거나 혹은 구매자가 이체를 진행했지만 판매자가 입금확인의사표시를 수행하지 않은 경우를 교착상태로 판단합니다. 첫번째 경우가 입증된 경우 판매자에게 코인을 반환하며, 두번째 경우에는 거래가 재개될 수 있도록 가능한 판매지를 세션으로 복귀시키기 위한 조치를 진행합니다.</p>
                <h3 className="miniHeading">서비스 이용 제한 규정</h3>
                <p>
                    <strong>가.</strong> 서비스를 통한 거래의 안전성과 신뢰성을 훼손시킬 수 있다고 판단 될 경우 서비스 이용을 제한 할 수 있습니다.
                    <br /><strong>나.</strong> 회사는 사용자 중 서비스를 이용하여, 다른 사용자들에게 손해를 입히거나, 손해를 입힐 수 있다고 판단 되는 경우 서비스 이용을 제한 할 수 있습니다.
                    <br /><strong>다.</strong> 서비스 이용제한의 상세 내역은 아래 표와 같습니다.
                    <Table8 />
                    <strong>라.</strong> 회사는 서비스 이용이 제한 된 사용자에 대해서 해당 사용자가 거래거부를 할 수 밖에 없었던 사유를 소명하거나, 거래상대방과의 합의가 있었음을 소명하는 경우, 회사의 본인인증 서비스를 통해 본인 확인이 되었을 경우 등에는 해당 사용자의 신용점수 등 제반 사정을 감안하여 거부횟수의 전부 또는 일부를 차감함으로써 서비스 이용 제한을 해제 할 수 있습니다.
                    <br /><strong>마.</strong> 중복적으로 서비스 이용 제한 발생 시 모든 서비스 이용 제한 조건을 갖췄을 경우에만 서비스 이용 제한 해제가 가능합니다.
                </p>
            </section>
        </React.Fragment>
    )
}

export function PersonalInfo() {
    const [view, setTable1] = useState(false);
    const [view2, setTable2] = useState(false);

    return (
        <React.Fragment>
            <h2 className='bigHeading' style={{textAlign: "center"}}>개인정보처리방침</h2>
            <ul>
                <li>
                    <h3>1. <span className="miniHeading">개인정보 수집항목</span></h3>
                    <p>
                    회사는 <strong>사용자의 개인정보를 수집하는 경우</strong> 반드시 사전에 사용자에게 해당 내용을 알린 후 <strong>동의를 받아 수집</strong>하도록 하겠습니다.
                    <br/><br/>
                    회사는 사용자의 개인정보 수집을 위하여 아래의 사항을 준수하고 있습니다.
                    <ul>
                        <li><strong>ㄱ.</strong> 사용자는 서비스를 제공함에 있어서 최소한의 개인정보만을 수집하겠습니다.<br/>
                            ① <strong>필수 정보:</strong> 서비스를 수행하는 데 있어 필수적인 정보<br/>
                            ② <strong>선택 정보:</strong> 이벤트 등 사용자는 특화된 서비스를 제공하기 위한 추가적인 정보
                            <span className="greytext">(선택 정보를 입력하지 않는 경우에도 회원 가입이 가능합니다.)</span>
                        </li>
                        <li>
                        <strong>ㄴ.</strong> 민감한 개인정보를 수집하지 않습니다.<br/>
                        ① 인종, 사상, 신조, 건강상태 등 사용자의 기본적 인권 침해의 우려가 있는 개인정보는 수집하지 않습니다.<br/>
                        ② 단, 법령에 근거하여 부득이하게 수집해야 하는 경우 사용자는 해당 사실을 안내하고, 사전 동의를 구하겠습니다.
                        </li>
                        <li>
                        <strong>ㄷ.</strong> 회사는 사용자들에게 동의를 받아 아래와 같은 방법으로 개인정보를 수집합니다.<br/>
                        ① 앱, 전화, 고객센터 상담, 이메일, 이벤트응모 <br/>
                        </li>
                    </ul>
                    아래 페이지를 통하여 사용자가 확인하시는 시점을 기준으로 최신화 된 개인정보 수집항목을 제공하겠습니다.
                    </p>
                    <button className="textButton" onClick={() => setTable1(!view)}>개인정보 수집항목 자세히 보기</button>
                    {view && <Table1 />}
                </li>
                <li>
                    <h3>2. <span className="miniHeading">개인정보 이용목적</span></h3>
                    <p>
                    회사는 사용자의 개인정보를 다음과 같은 목적으로만 이용하며, 사용자들께 <strong>사전에 밝힌 목적 이외에 다른 목적으로 사용하지 않습니다.</strong>
                        <br/><br/>
                        <ul>
                            <li><strong>ㄱ. 회원 서비스 제공:</strong> 본인 인증, 개인 식별, 연령 제한 서비스의 제공, 본인 의사 확인, 가입 여부 확인, 재가입 제한, 문의사항 및 분쟁 조정기록, 회원 탈퇴 여부 확인, 공지사항 전달, 불량 이용자 이용 제한 조치</li>
                            <li><strong>ㄴ.  서비스 제공</strong></li>
                            <li><strong>ㄷ. 신규 서비스 개발 및 마케팅·광고에의 활용:</strong> 신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 접속 빈도 파악, 회원의 서비스이용에 대한 통계</li>
                        </ul>
                    
                    아래 페이지를 통하여 사용자가 확인하시는 시점을 기준으로 최신화 된 개인정보 수집항목을 제공하겠습니다.
                    <br />
                    또한 회사가 수집한 사용자의 개인정보는 <strong>수집 목적 및 제공 받은 목적이 달성될 때까지만 보유</strong>하고 있으며, 사용자가 탈퇴를 요청하시거나 동의를 철회하는 경우 즉시 파기하겠습니다.
                    <br />
                    다만, 관계 법령에 의해 보관해야 하는 정보는 <strong>법령이 정한 바에 따라 보관</strong>합니다.
                    <br />
                    <span className="greytext">(사용자의 개인정보는 앱을 삭제하여도 회사가 개인정보를 보유하고 있습니다. 개인정보 파기를 위해서는 회원탈퇴 또는 고객센터로 문의가 필요합니다.)</span>
                    </p>
                    <button className="textButton" onClick={() => setTable2(!view2)}>관계 법령에 의한 개인정보 보관 자세히 보기</button>
                    {view2 && <Table2 />}
                </li>
                <li>
                    <h3>3. <span className="miniHeading">개인정보 제공</span></h3>
                    <p>
                        회사는 사용자에게 원활하고, 향상된 서비스를 제공하기 위하여 사용자의 개인정보를 제3자에게 제공할 수 있습니다. 
                        서비스 제공 및 거래 안전 등의 목적으로 최소한의 개인정보만을 제공하며, 
                        <strong>개인정보를 제3자에게 제공하는 경우</strong> 반드시 사전에 사용자에 해당 사실을 알리고 <strong>동의</strong>를 받도록 하겠습니다.
                        <br /><br />
                        다만 아래와 같은 경우는 예외로 하고 있습니다.
                        <ul>
                            <li><strong>ㄱ.</strong> 관계 법령에 의하여 행정목적 또는 수사상의 목적으로 관계 기관으로부터의 요구가 있을 경우</li>
                            <li><strong>ㄴ.</strong> 통계 작성, 학술 연구나 시장 조사를 위하여 특정 개인을 식별할 수 없는 비식별화 형태로 광고주, 협력사나,연구 단체 등에 제공하는 경우</li>
                            <li><strong>ㄷ.</strong> 기타 관계 법령에서 정한 절차에 따른 요청이 있는 경우</li>
                            <li><strong>ㄹ.</strong> 회사가 제공하는 서비스를 통해 거래가 성사되어 거래 안전을 위해 쌍방 당사자에게 거래 관련 정보를 필요 범위 내에서 제공하는 경우</li>
                        </ul>
                    </p>
                </li>
                <li>
                    <h3>4. <span className="miniHeading">개인정보 파기</span></h3>
                    <p>
                        사용자의 개인정보는 원칙적으로 <strong>개인정보의 수집 및 이용목적이 달성</strong>되면 지체 없이 <strong>파기</strong>합니다.
                        <br />
                        다만 관계 법령에 의해 보관해야 하는 정보는 법령에 의합니다.
                        <br />
                        또한, ‘개인정보 유효기간제’에 따라 1년 또는 달리 정한 기간동안 서비스를 이용하지 않은 사용자의 개인정보를 별도로 분리 보관하고 있습니다. 이때 별도 저장 관리 되는 개인정보는 법령에 의하지 않고는 이용되지 않습니다.
                        <br /><br />
                        파기 절차 및 방법은 아래와 같습니다.
                        <Table3 />
                    </p>
              
                </li>
                <li>
                    <h3>5. <span className="miniHeading">기타사항</span></h3>
                    <p>
                        <strong>사용자의 개인정보 보호를 위한 회사의 노력</strong>
                        <br />
                        회사는 사용자의 소중한 개인정보를 보호하기 위하여 아래와 같은 노력을 하고 있습니다.
                        <ul>
                            <li><strong>ㄱ.</strong> 사용자의 개인정보가 해킹이나 바이러스에 의해 유출되거나 훼손되는 것을 막고 있습니다.</li>
                            <li><strong>ㄴ.</strong> 사용자의 개인정보 훼손을 막기 위해 백업, 백신프로그램, 침입차단프로그램 등 <strong>기술적 조치</strong>를 다하고 있습니다.</li>
                            <li><strong>ㄷ.</strong> 사용자의 개인정보 접근권한을 최소한으로 운영하고 있으며, 임직원을 대상으로 보안 기술 습득 및 개인정보 보호 의무등에 대하여 <strong>정기교육</strong>을 실시하고 있습니다.</li>
                        </ul>
                    </p>
                    <p>
                        <strong>개인정보 보호를 위한 의견 수렴</strong>
                        <br />
                        회사는 사용자의 의견을 소중하게 생각하며, 사용자은 개인정보 관련 의문사항에 대하여 담당부서로 문의할 수 있습니다.(고객센터: <a target="_blank" rel="noreferrer" href="mailto:help@betweenbits.io">help@betweenbits.io</a>)
                        <br /><br />
                        <strong>그외 개인정보에 관한 상담이 필요한 경우에는 개인정보침해신고센터, 대검찰청 사이버수사과, 경찰청 사이버수사국 등으로 문의하실 수 있습니다.</strong>
                        <ul>
                            <li>개인정보침해신고센터 (<a target="_blank" rel="noreferrer" href="http://privacy.kisa.or.kr">http://privacy.kisa.or.kr</a> / 국번 없이 118)</li>
                            <li>대검찰청 사이버수사과 (<a target="_blank" rel="noreferrer" href="http://www.spo.go.kr">http://www.spo.go.kr</a> / 국번 없이 1301)</li>
                            <li>경찰청 사이버수사국 (<a target="_blank" rel="noreferrer" href="http://ecrm.cyber.go.kr">http://ecrm.cyber.go.kr</a> / 국번 없이 182)</li>
                        </ul>
                        <Table4 />
                        <strong>부가 방침</strong><br/>
                        현 개인정보처리방침은 내용의 추가, 삭제 및 수정이 있을 시에는 <strong>개정 최소 7일 전</strong>부터 온라인 홈페이지의 <strong>“공지사항”</strong>란을 통해 공지할 것 입니다. 다만, 개인정보의 수집, 제3자 제공 등 회원의 권리에 중대한 변경이 있는 경우에는<strong>최소 30일 이상의 사전 유예기간</strong>을 두고 공지합니다.
                    </p>
                    </li>
                <li>
                    <h3>6. <span className="miniHeading">부칙</span></h3>
                    <p>
                    <strong>이 방침은 2023년 08월 30일 적용되며, 이전의 방침은 본 방침으로 변경됩니다.</strong>
                    <br />
                    - 개인정보처리방침 시행일자 : 2023년 08월 11일
                    </p>   
                </li>
            </ul>
        </React.Fragment>
    );
}

function Table1() {
    const data = [
        {
            name: "사용자가입",
            required: "(공통) 이메일, 비밀번호, 휴대폰번호",
            option: "은행 계좌번호, 코인주소정보 그 외 선택 항목",
            time: "사용자탈퇴 후 파기 또는 법령에 따른 보존기간"
        },
        {
            name: "본인확인",
            required: "성명, 생년월일, 성별, 이동통신사, 내/외국인 정보, 휴대폰번호, I-PIN, 연계정보, 중복가입확인정보(DI)",
            option: ""
        },{
            name: "계좌확인",
            required: "은행명, 계좌번호",
            option: ""
        },{
            name: "계약이행 및 약관변경 등 고지사항 전달, 고객 민원처리 등 원활한 의사소통 경로 확보",
            required: "성명, 이메일, 휴대폰번호",
            option: ""
        },{
            name: "회원 서비스 제공 및 계약의 이행",
            required: "성명, 이메일, 휴대폰번호",
            option: ""
        },{
            name: "부정이용방지, 비인가 사용 방지, 분쟁조정 해결을 위한 기록보존",
            required: "성명, 이메일, 휴대폰번호",
            option: ""
        },{
            name: "1:1 이용문의",
            required: "이메일",
            option: "이메일, 휴대폰번호"
        },{
            name: "이벤트/서비스 안내 등의 마케팅 정보 제공",
            required: "",
            option: "성명, 이메일, 휴대폰번호"
        }

    ]
    return (
            <div className='tableWrap'>
                <table id="table1">
                    <tr>
                        <th>서비스 명</th>
                        <th>필수정보</th>
                        <th>선택정보</th>
                        <th>보유기간</th>
                    </tr>
                    {data.map((val, key) => {
                        return (
                            <tr key={key}>
                                <td>{val.name}</td>
                                <td>{val.required}</td>
                                <td>{val.option}</td>
                                {key === 0 && <td rowspan="8">{val.time}</td>}
                            </tr>
                        )
                    })}
                </table>
            </div>);
}

function Table2() {
    const data = [
        {
            name: "상품의 등록",
            var2: "전자상거래 등에서의 소비자보호에 관한 법률",
            var3: "6개월",
        },
        {
            name: "계약 또는 청약 철회",
            var3: "5년",
        },{
            name: "대금결제 및 재화",
            var3: "3년",
        },{
            name: "소비자의 불만, 분쟁처리",
            var3: "3년",
        },{
            name: "이용 관련 로그기록",
            var2: "통신비밀보호법",
            var3: "3개월",
        },

    ]
    return (
            <div className='tableWrap'>
                <table className="fullTable">
                    <tr>
                        <th>관련기록</th>
                        <th>보관 근거 법령</th>
                        <th>보관 기간</th>
                    </tr>
                    {data.map((val, key) => {
                        return (
                            <tr key={key}>
                                <td>{val.name}</td>
                                {val.var2 && <td rowspan="4">{val.var2}</td>}
                                <td>{val.var3}</td>
                            </tr>
                        )
                    })}
                </table>
            </div>);
}

function Table3() {
    return (
            <div className='tableWrap'>
                <table className="fullTable">
                    <tr>
                        <th>파기절차</th>
                    </tr>
                    <tr>
                        <td>
                        - 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져 <span className='greytext'>(종이의 경우 별도의 서류함)</span>
                        <br />내부 방침 및 기타 관련 법령에 의한 정보 보호 사유에 따라 <span className='greytext'>(보유 및 이용기간 참조)</span> 일정 기간 저장된 후 파기됩니다.<br/>
                        - 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.

                        </td>
                    </tr>
                    <tr>
                        <th>파기방법</th>
                    </tr>
                    <tr>
                        <td>
                        - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다. <br/>
                        - 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
                        </td>
                    </tr>
                </table>
            </div>);
}

function Table4() {
    return (
            <div className='tableWrap'>
                <table className="fullTable">
                    <tr>
                        <th>개인정보 보호 책임자</th>
                        <th>개인정보 보호 담당자</th>
                    </tr>
                    <tr>
                        <td>
                            직위: 대표이사<br/>
                            성명: 김동욱<br/>
                            이메일: <a target="_blank" rel="noreferrer" href="mailto:help@betweenbits.io">help@betweenbits.io</a>
                        </td>
                        <td>
                            소속 : 기술전략실<br/>
                            직위: 임원<br/>
                            성명: 한경수<br/>
                            이메일: <a target="_blank" rel="noreferrer" href="mailto:help@betweenbits.io">help@betweenbits.io</a>
                        </td>
                    </tr>
                </table>
            </div>);
}

export function Table5() {
    const data = [
        {
            name: "BRONZE",
            var2: "0 - 49",
            var3: "-",
            var4: "수수료: 0.5%"
        },
        {
            name: "SILVER",
            var2: "50 - 299",
            var3: "1,000,000원 이상",
            var4: "수수료: 0.5%, Metrics ‘더 보기’ 클릭 가능, Column에 댓글 작성 가능"
        },{
            name: "GOLD",
            var2: "300 이상",
            var3: "10,000,000원 이상",
            var4: "수수료: 0.4%, Metrics ‘더 보기’ 클릭 가능, Column에 댓글 작성 가능"
        }
    ]
    return (
            <div className='tableWrap'>
                <table id="table5">
                    <tr>
                        <th rowspan="2">등급</th>
                        <th colspan="2" style={{textAlign: "center", borderBottom: "1px solid white"}}>조건</th>
                        <th rowspan="2">혜택</th>
                    </tr>
                    <tr>
                        <th>포인트</th>
                        <th>누적 거래액</th>
                    </tr>

                    {data.map((val, key) => {
                        return (
                            <tr key={key}>
                                <td>{val.name}</td>
                                <td>{val.var2}</td>
                                <td>{val.var3}</td>
                                <td>
                                    {val.var4}
                                </td>
                            </tr>
                        )
                    })}
                </table>
            </div>);
}

function Table6() {
    const data = [
        {
            name: "사용자",
            var1: "이 약관을 승인하고 회원가입을 하여 회사와 서비스이용계약을 체결한 자를 말합니다."
        },
        {
            name: "이메일(ID)",
            var1: "사용자의 식별과 서비스 이용을 위하여 회원이 설정하고 회사가 승인한 이메일 정보를 말합니다."
        },
        {
            name: "비밀번호",
            var1: "사용자의 동일성 확인과 회원정보의 보호를 위하여 회원이 설정하고 회사가 승인한 문자와 숫자의 조합을 말합니다."
        },
        {
            name: "본인인증",
            var1: "사용자의 안전거래를 위하여 회사가 제공하는 각종 인증절차 수단을 말합니다."
        },
        {
            name: "판매자",
            var1: "코인을 판매할 의사로 해당 코인판매정보를 회사가 온라인으로 제공하는 양식에 맞추어 등록한 사용자를 말합니다."
        },
        {
            name: "구매자",
            var1: "코인을 구매할 의사로 해당 코인구매정보를 회사가 온라인으로 제공하는 양식에 맞추어 등록한 사용자를 말합니다."
        },
        {
            name: "직거래",
            var1: "회사가 제공하는 편의기능을 통해, 거래 상대방을 알게 된 후, 시스템 밖에서 다시 접촉하여 서비스와 무관하게 직접 거래하는 것을 말합니다."
        },
        {
            name: "설정화면",
            var1: "회사가 각 사용자에 대한 서비스 이용내역 등 신속하고 편리하게 자신의 관련 정보를 확인할 수 있도록 제공하는 서비스화면을 말합니다."
        },
        {
            name: "사용자등급",
            var1: "사용자간 판매 또는 구매가 이루어진 경우 회사가 정한 일정 기준에 따라 사용자에게 부여하는 거래등급을 말합니다."
        },
        {
            name: "포인트",
            var1: "회사가 고객과의 거래에 부수하여 고객에게 제공하는 것으로 등급의 산정기준이 되며 회사가 이를 운영하는 제도를 말합니다."
        }
    ]
    return (
        <table className="fullTable">
        <tr>
            <th style={{minWidth: "100px"}}>용어</th>
            <th>정의</th>
        </tr>
        {data.map((val, key) => {
                        return (
                            <tr key={key}>
                                <td>{val.name}</td>
                                <td>{val.var1}</td>
                            </tr>
                        )
                    })}
        </table>
    );
}

function Table7() {
    const data = [
        {
            name: "거래수",
            var1: "1 성공적인 거래 \u2192 +5P\n5번의 연속적 성공적인 거래 \u2192 +10P"
        },
        {
            name: "거래금 (원화)",
            var1: "누적 거래 대금 1,000,000원 \u2192 +10P \n누적 거래 대금 10,000,000원 \u2192 +50P \n누적 거래 대금 100,000,000원 \u2192 +100P"
        },
        {
            name: "시간",
            var1: "5분 이내 action \u2192 +2P"
        }

    ]
    return (
        <table className="fullTable">
        <tr>
            <th>종류</th>
            <th>영향</th>
        </tr>
        {data.map((val, key) => {
                        return (
                            <tr key={key}>
                                <td>{val.name}</td>
                                <td>{val.var1}</td>
                            </tr>
                        )
                    })}
        </table>
    );
}

function Table8() {
    const data = [
        {
            name: "영구정지",
            required: "위법 행위 및 사고 사용자",
            option: "문제해결, 충분한 소명 및 재발방지 약속을 조건으로 사측이 해제 여부를 판단",
            time: "로그인 제한"
        },
        {
            name: "거래정지",
            required: "일방적 거래정지 사유 제공 3회 연속 (3회 연속 벌점 부과)",
            option: "문제해결, 충분한 소명 및 재발방지 약속",
            time: "코인거래등록 및 판매/구매신청 제한"
        },
        {
            name: "",
            required: "본 서비스에서 알게된 상대와 직거래 적발 시",
            option: "문제해결, 충분한 소명 및 재발방지 약속",
            time: "코인거래등록 및 판매/구매신청 제한"
        },
        {
            name: "이용정지",
            required: "해킹관련, 타인(가족) 명의 가입 시",
            option: "문제해결 시 또는 본인인증",
            time: "코인거래등록 및 판매/구매신청 제한"
        }

    ]

    return (
            <div className='tableWrap'>
                <table>
                    <tr>
                        <th>구분</th>
                        <th>정지사유</th>
                        <th>해제조건</th>
                        <th>정지효력</th>
                    </tr>
                    {data.map((val, key) => {
                        return (
                            <tr key={key}>
                                {key === 1 && <td rowspan="2">{val.name}</td>}
                                {key === 3 && <td>{val.name}</td>}
                                <td>{val.required}</td>
                                <td>{val.option}</td>
                                <td>{val.time}</td>
                            </tr>
                        )
                    })}
                </table>
            </div>);
}