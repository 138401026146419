import './App.css';
import React  from 'react';
import { Outlet } from "react-router-dom";
import Navigation from './components/Navigation';
import Footer from './components/Footer';

function App() {
  return ( 
    <React.Fragment>
        <Navigation />
        <Outlet />
        <Footer />
    </React.Fragment>
    
  );
}

export default App;


