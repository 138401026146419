import * as React from 'react';
import '../styles/Wavy.css';
import Google  from '../images/google.png';
import GoogleNew  from '../images/google.webp';
import AppStore  from '../images/appstore.png';
import AppStoreNew  from '../images/appstore.webp';

export default function Wavy() {
  return ( 
    <>
        <div className='wave-wrapper'>
        <svg className="waves" xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
        <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(134,194,222,0.4)" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(62, 120, 157, 0.6)" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(134,194,222,0.6)" />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(62, 120, 157, 1)" />
        </g>
        </svg>
        </div>
        <div className='wave-download'>
            <h2>모빅매니아 다운로드</h2>
            <div className='download-logos'>
                        <div className='download-logo'>
                            <a href="https://play.google.com/store/apps/details?id=com.btw.mobickmania" target="_blank" rel="noreferrer">
                            <picture>
                                <source type="image/webp" srcSet={GoogleNew}/>
                                <source type="image/png" srcSet={Google}/>
                                <img alt="play store icon"src={Google} height="50px"  width="168px"/>
                                </picture>
                                </a>
                        </div>
                        <div className='download-logo'>
                            <a href="https://apps.apple.com/kr/app/mobick-mania/id6466781851" target="_blank" rel="noreferrer">
                            <picture>
                                <source type="image/webp" srcSet={AppStoreNew}/>
                                <source type="image/png" srcSet={AppStore}/>
                                <img alt="app store icon" src={AppStore} height="50px" width="149.33px"/>
                                </picture>
                                </a>
                        </div>
            </div> 
        </div>
    </>
  );
}